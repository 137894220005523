import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";
import { Row, Col, Tabs, Card, Button, Divider, Anchor, Modal } from "antd";
import { BrowserView, MobileView } from "react-device-detect";
import { useAuthState } from "../../provider/AuthProvider";
import type { TabsProps } from "antd";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";
import * as String from "../../commons/string";
import * as Common from "../../commons/common";
import * as TypeUtils from "../../commons/typeUtils";
import * as Options from "../../commons/options";
import * as Utils from "../../utils/utils";

import freeItem1 from "../../assets/images/home/rent/free_item1.jpg";
import freeItem2 from "../../assets/images/home/rent/free_item2.jpg";
import freeItem3 from "../../assets/images/home/rent/free_item3.jpg";
import freeItem4 from "../../assets/images/home/rent/free_item4.jpg";
import paidItem1 from "../../assets/images/home/rent/paid_item1.jpg";
import paidItem2 from "../../assets/images/home/rent/paid_item2.jpg";
import paidItem3 from "../../assets/images/home/rent/paid_item3.jpg";
import paidItem4 from "../../assets/images/home/rent/paid_item4.jpg";
import ReservationInfo from "../../component/rent/ReservationInfo";
import ReservationDetail from "../../component/rent/detail/ReservationDetail";
import CarOption from "../../component/rent/CarOption";
import Title from "../../component/rent/Title";
import Info from "../../component/rent/Info";
import LayoutFooter from "../../component/LayoutFooter";
import MobileDrawer from "../../component/rent/MobileDrawer";
import Faq from "../../component/rent/Faq";
import styles from "./Details.module.css";
import moment from "moment";
import RefundInfo from "../../component/rent/RefundInfo";
import PenaltyInfo from "../../component/rent/PenaltyInfo";
import DetailCarInfo from "../../component/rent/DetailCarInfo";
import CustomLoading from "../../component/rent/CustomLoading";

function Details() {
    const { Link } = Anchor;
    const location = useLocation();
    const navigate = useNavigate();
    const userDetails = useAuthState();
    const param = useParams();

    const [rentCar, setRentCar] = useState<TypeDTO.RentCarDto>();
    const [values, setValues] = useState<TypeUtils.ReservationType>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [defaultParam, setDefaultParam] = useState<any>();
    const [disabledRanges, setDisabledRanges] = useState<Array<TypeUtils.DateType>>();
    const [rentPeriodLimits, setRentPeriodLimits] = useState<Array<TypeDTO.RentPeriodLimitDto>>();
    const [loadingComplete, setLoadingComplete] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [isLogout, setIsLogout] = useState<boolean>(true);
    const [rentPeakSeasons, setRentPeakSeasons] = useState<Array<TypeDTO.RentPeakSeasonDto>>();

    useEffect(() => {
        userDetails?.user && setIsLogout(false);
    }, [userDetails]);

    useEffect(() => {
        setDefaultParam(param);

        if (param && JSON.stringify(param) !== JSON.stringify(defaultParam)) {
            requestGetRentCar(param.carId);
            requestGetRents(param.carId);
            requestGetRentPeriodLimits();
            requestGetRentPeakSeasons();
            setValues((prev) => ({
                ...prev,
                startDate: param.startDate,
                endDate: param.endDate,
            }));
        }
    }, [param]);

    const setTitle = (title: string) => {
        return (
            <>
                <h3 className="text-center" style={{ fontWeight: "900" }}>
                    {title}
                </h3>
                <Divider className="title-divider mb-5" />
            </>
        );
    };

    const title = {
        regionName: {
            content: rentCar?.rentRegion.rentRegionName,
        },
        title: {
            content: rentCar?.title,
        },
        description: {
            content: rentCar?.description,
        },
        priceDiscounted: {
            content: rentCar?.discountPrice,
        },
        price: {
            content: rentCar?.price,
        },
        tags: rentCar?.rentRestrictions,
    };

    const Bayrun4Type = {
        title: rentCar?.title,
        carType: "bayrun4",
        options: Options.bayrun4RentAppliances,
        specifications: [
            {
                th: "치수(mm)",
                td: "5960×2020×2640",
            },
            {
                th: "최대 속도(km/h)",
                td: "100",
            },
            {
                th: "최대 주행거리(km)",
                td: "303.68 (환경부공인 인증)",
            },
            {
                th: "배터리(KW)",
                td: "106 (Li-Ion)",
                className: "text-danger",
            },
            {
                th: "충전 모드",
                td: "Combo 1 (CCS)",
            },
            {
                th: "안전사양",
                td: "LDWS, ESC, ADAS, AIR BAG",
            },
        ],
        freeItems: [
            {
                image: freeItem1,
                categoryName: "캠핑 세트",
                itemNames: [
                    "삼성 플레이트 인덕션(1구)",
                    "코베아 코펠세트(냄비, 볶음팬, 프라이팬, 인덕션 패드 2개)",
                    "어닝 고정 세트, 팩망치",
                    "멀티탭 3구, 모기약(리퀴드타입), 전기 파리채",
                    "일회용 수세미, 주방세제, 행주",
                    "종량제봉투 10L 1매",
                ],
            },
            {
                image: freeItem2,
                categoryName: "조리도구 세트",
                itemNames: ["식칼, 가위", "국자, 그물국자, 뒤집개, 볶음스푼, 실리콘 집게", "도마"],
            },
            {
                image: freeItem3,
                categoryName: "식기 세트(1박 기준)",
                itemNames: ["밥그릇4개, 국그릇4개, 접시2개, 일회용 수저4개"],
            },
        ],
        rentPaidItems: [
            {
                image: paidItem1,
                categoryName: "테이블×의자 2인 세트",
                itemNames: ["테이블2개, 의자2개"],
                price: "10,000원",
            },
            {
                image: paidItem2,
                categoryName: "테이블×의자 4인 세트",
                itemNames: ["테이블2개, 의자4개"],
                price: "20,000원",
            },
            {
                image: paidItem3,
                categoryName: "침구 2인 세트",
                itemNames: ["베개2개, 이불1개"],
                notes: ["모든 침구류의 린넨은 살균 세탁 후 출차합니다."],
                price: "15,000원",
            },
            {
                image: paidItem4,
                categoryName: "침구 4인 세트",
                itemNames: ["미니베개2개, 베개2개, 이불2개"],
                notes: ["모든 침구류의 린넨은 살균 세탁 후 출차합니다."],
                price: "20,000원",
            },
        ],
    };

    const SType = {        
        title: rentCar?.title,
        carType: "sType",
        options: Options.STypeAppliances,
        specifications: [
            {
                th: "치수(mm)",
                td: "5960×2020×2640",
            },
            {
                th: "최대 속도(km/h)",
                td: "100",
            },
            {
                th: "최대 주행거리(km)",
                td: "303.68 (환경부공인 인증)",
            },
            {
                th: "배터리(KW)",
                td: "106 (Li-Ion)",
                className: "text-danger",
            },
            {
                th: "충전 모드",
                td: "Combo 1 (CCS)",
            },
            {
                th: "안전사양",
                td: "LDWS, ESC, ADAS, AIR BAG",
            },
        ],
        freeItems: [
            {
                image: freeItem1,
                categoryName: "캠핑 세트",
                itemNames: [
                    "삼성 플레이트 인덕션(1구)",
                    "코베아 코펠세트(냄비, 볶음팬, 프라이팬, 인덕션 패드 2개)",
                    "어닝 고정 세트, 팩망치",
                    "멀티탭 3구, 모기약(리퀴드타입), 전기 파리채",
                    "일회용 수세미, 주방세제, 행주",
                    "종량제봉투 10L 1매",
                ],
            },
            {
                image: freeItem2,
                categoryName: "조리도구 세트",
                itemNames: ["식칼, 가위", "국자, 그물국자, 뒤집개, 볶음스푼, 실리콘 집게", "도마"],
            },
            {
                image: freeItem3,
                categoryName: "식기 세트(1박 기준)",
                itemNames: ["밥그릇4개, 국그릇4개, 접시2개, 일회용 수저4개"],
            },
        ],
        rentPaidItems: [
            {
                image: paidItem1,
                categoryName: "테이블×의자 2인 세트",
                itemNames: ["테이블2개, 의자2개"],
                price: "10,000원",
            },
            {
                image: paidItem2,
                categoryName: "테이블×의자 4인 세트",
                itemNames: ["테이블2개, 의자4개"],
                price: "20,000원",
            },
            {
                image: paidItem3,
                categoryName: "침구 2인 세트",
                itemNames: ["베개2개, 이불1개"],
                notes: ["모든 침구류의 린넨은 살균 세탁 후 출차합니다."],
                price: "15,000원",
            },
            {
                image: paidItem4,
                categoryName: "침구 4인 세트",
                itemNames: ["미니베개2개, 베개2개, 이불2개"],
                notes: ["모든 침구류의 린넨은 살균 세탁 후 출차합니다."],
                price: "20,000원",
            },
        ],
    };

    const getCarType = () => {
        switch (rentCar?.car.carType.typeName) {
            case "S타입":
                return SType;
            case "배이런(4인승)":
                return Bayrun4Type;
            default:
                return Bayrun4Type;
        }
    };

    const tabItems: TabsProps["items"] = [
        {
            key: "1",
            label: "세부정보",
            children: (
                <div className="mt-3">
                    {setTitle("세부정보")}
                    <DetailCarInfo info={getCarType()} />
                </div>
            ),
        },
        {
            key: "2",
            label: "이용 안내",
            children: (
                <div className="mt-3">
                    {setTitle("이용 안내")}
                    <Info
                        showInfo={{ show: true, className: "mb-5" }}
                        showMethod={{ show: true, showTitle: true, className: "mb-5" }}
                        showCondition={{ show: true, showTitle: true, showDivider: false, className: "mb-5" }}
                        showInsurance={{ show: true, showTitle: true, showDivider: false, className: "mb-5" }}
                        showMap={{ show: true, showTitle: true, showDivider: true, className: "mb-5" }}
                        rentRegion={values?.rentCar?.rentRegion}
                    />
                </div>
            ),
        },
        {
            key: "3",
            label: "취소 환불 규정",
            children: (
                <div className="mt-3">
                    {setTitle("취소 환불 규정")}
                    <RefundInfo />
                </div>
            ),
        },
        {
            key: "4",
            label: "위약금 규정",
            children: (
                <div className="mt-3">
                    {setTitle("위약금 규정")}
                    <PenaltyInfo
                        showReturnDelay={{ show: true, showTitle: true, className: "mt-5" }}
                        showUncharged={{ show: true, showTitle: true, className: "mt-5" }}
                        showBreach={{ show: true, showTitle: true, className: "mt-5" }}
                        showEtc={{ show: true, showTitle: true, className: "mt-5" }}
                    />
                </div>
            ),
        },
        {
            key: "5",
            label: "자주하는 질문",
            children: (
                <div className="mt-3">
                    {setTitle("자주하는 질문")}
                    <Faq faqIds={[8, 9, 6, 13, 7, 14]} />
                </div>
            ),
        },
    ];

    const requestAxiosGetRentCar = async (id: number) => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_CAR_URL + id);
        return response.data;
    };

    const {
        loading: loadingGetRentCar,
        error: errorGetRentCar,
        data: resultGetRentCar,
        execute: requestGetRentCar,
    } = useAsyncAxios(requestAxiosGetRentCar);

    useEffect(() => {
        if (!resultGetRentCar) return;
        console.log("resultGetRentCar", resultGetRentCar);
        setLoadingComplete(true);

        if (resultGetRentCar.rentCar.rentRegion.b2bId && resultGetRentCar.rentCar.rentRegion.b2bId !== userDetails?.user?.b2b?.b2bId) {
            Modal.warning({ centered: true, title: String.msg_onlyB2bMember, okText: String.confirm, onOk() {} });
            navigate(Common.PAGE_RENT_HOME);
            return;
        }

        setRentCar(resultGetRentCar.rentCar);
        setValues((prevValues) => ({
            ...prevValues,
            regionId: resultGetRentCar?.rentCar?.rentRegion?.rentRegionId,
            rentCar: resultGetRentCar.rentCar,
        }));

        setIsDisabled(resultGetRentCar.rentCar.car.carType.typeId === 3);

        if (loadingGetRentCar || loadingGetRents || loadingGetRentPeriodLimits) {
            setLoadingComplete(false);
        }
    }, [resultGetRentCar]);

    useEffect(() => {
        if (!errorGetRentCar) return;
        console.log("errorGetRentCar", errorGetRentCar);
    }, [errorGetRentCar]);

    const requestAxiosGetRents = async (rentCarId: number) => {
        const response = await utilAxiosWithAppToken().get(Request.RENTS_URL, {
            params: {
                rentCarId,
                startDate: moment().startOf("month").format("YYYY-MM-DD"),
            },
        });
        return response.data;
    };

    const { loading: loadingGetRents, error: errorGetRents, data: resultGetRents, execute: requestGetRents } = useAsyncAxios(requestAxiosGetRents);

    useEffect(() => {
        if (!resultGetRents) return;
        console.log("resultGetRents", resultGetRents);

        setLoadingComplete(true);
        setDisabledRanges(
            resultGetRents.rents.map((rent: TypeDTO.RentDto) => ({
                startDate: rent.startDate,
                endDate: rent.endDate,
            }))
        );

        if (loadingGetRentCar || loadingGetRents || loadingGetRentPeriodLimits) {
            setLoadingComplete(false);
        }
    }, [resultGetRents]);

    useEffect(() => {
        if (!errorGetRents) return;

        console.log("errorGetRents", errorGetRents);
    }, [errorGetRents]);

    const requestAxiosGetRentPeriodLimits = async () => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_PERIOD_LIMITS_URL, {
            params: {
                startDate: moment().startOf("month").format("YYYY-MM-DD"),
            },
        });
        return response.data;
    };

    const {
        loading: loadingGetRentPeriodLimits,
        error: errorGetRentPeriodLimits,
        data: resultGetRentPeriodLimits,
        execute: requestGetRentPeriodLimits,
    } = useAsyncAxios(requestAxiosGetRentPeriodLimits);

    useEffect(() => {
        if (!resultGetRentPeriodLimits) return;
        console.log("resultGetRentPeriodLimits", resultGetRentPeriodLimits);

        setLoadingComplete(true);
        setRentPeriodLimits(resultGetRentPeriodLimits.rentPeriodLimits);

        if (loadingGetRentCar || loadingGetRents || loadingGetRentPeriodLimits) {
            setLoadingComplete(false);
        }
    }, [resultGetRentPeriodLimits]);

    useEffect(() => {
        if (!errorGetRentPeriodLimits) return;

        console.log("errorGetRentPeriodLimits", errorGetRentPeriodLimits);
    }, [errorGetRentPeriodLimits]);

    const requestAxiosGetRentPeakSeasons = async () => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_PEAK_SEASONS_URL, {
            params: {
                startDate: moment().startOf("month").format("YYYY-MM-DD"),
            },
        });
        return response.data;
    };

    const {
        loading: loadingGetRentPeakSeasons,
        error: errorGetRentPeakSeasons,
        data: resultGetRentPeakSeasons,
        execute: requestGetRentPeakSeasons,
    } = useAsyncAxios(requestAxiosGetRentPeakSeasons);

    useEffect(() => {
        if (!resultGetRentPeakSeasons) return;
        console.log("resultGetRentPeakSeasons", resultGetRentPeakSeasons);

        setLoadingComplete(true);
        setRentPeakSeasons(resultGetRentPeakSeasons.rentPeakSeasons);

        if (loadingGetRentCar || loadingGetRents || loadingGetRentPeakSeasons) {
            setLoadingComplete(false);
        }
    }, [resultGetRentPeakSeasons]);

    useEffect(() => {
        if (!errorGetRentPeakSeasons) return;

        console.log("errorGetRentPeakSeasons", errorGetRentPeakSeasons);
    }, [errorGetRentPeakSeasons]);

    const showCheckpopup = () => {
        Modal.info({
            title: "대여조건",
            className: styles.customModal,
            bodyStyle: { maxHeight: "500px", top: "0" },
            content: (
                <div className="mt-4">
                    대여조건을 확인하시고 예약을 진행해 주세요.
                    <div className="custom-bolder-box mb-2 mt-3">
                        <Info showCondition={{ show: true }} />
                    </div>
                </div>
            ),
            okCancel: true,
            okText: "예약하기",
            cancelText: String.cancel,
            onOk() {
                navigate(Common.PAGE_RENT_RESERVATION);
            },
            onCancel() {},
            centered: true,
        });
    };

    const onClick = () => {
        values && sessionStorage.setItem(Common.SESSION_RESERVATION_INFO, JSON.stringify(values));
        if (isLogout) {
            navigate(Common.PAGE_LOGIN, { replace: true, state: location.pathname });
        } else {
            showCheckpopup();
        }
    };

    const onChange = (type: string, value: any) => {
        const nextValue = { [type]: value };
        if (type === "totalPrice") {
            if (value < 0) {
                nextValue.totalPrice = 0;
            }
            nextValue.rawTotalPrice = value;
        }
        setValues((prevValues) => ({
            ...prevValues,
            ...nextValue,
        }));
    };

    return (
        <>
            <MobileView className="mobile" style={{ overflowX: "hidden", overflowY: "scroll", minHeight: "calc(100vh - 75px)", display: "flex" }}>
                {loadingComplete === false ? (
                    <CustomLoading />
                ) : (
                    <div className={`${styles.body} ${styles.mobile} fadeIn space-page`}>
                        <Title info={title} className="d-block" />
                        <Divider />
                        <p style={{ fontWeight: "bold" }}>보유시설</p>
                        <CarOption options={rentCar?.rentOptions} />
                        <Divider className="custom-divider" />
                        <div>
                            <Anchor className="custom-achor" showInkInFixed={true} offsetTop={75}>
                                <Link href="#section1" title="세부정보" />
                                <Link href="#section2" title="이용 안내" />
                                <Link href="#section3" title="취소 환불" />
                                <Link href="#section4" title="위약금" />
                                <Link href="#section5" title="자주 하는 질문" />
                            </Anchor>
                            <div id="section1" className="my-4 h-100">
                                <>
                                    {setTitle("세부정보")}
                                    <DetailCarInfo info={getCarType()} />
                                </>
                            </div>
                            <div id="section2" className="mt-5">
                                {setTitle("이용 안내")}
                                <Info
                                    showInfo={{ show: true, showDivider: true }}
                                    showMethod={{ show: true, showTitle: true, showDivider: true }}
                                    showCondition={{ show: true, showTitle: true, showDivider: true }}
                                    showInsurance={{ show: true, showTitle: true, showDivider: true }}
                                    rentRegion={values?.rentCar?.rentRegion}
                                    showMap={{ show: true, showTitle: true }}
                                />
                            </div>
                            <div id="section3" className="mt-5">
                                {setTitle("취소 환불 규정")}
                                <RefundInfo />
                            </div>
                            <div id="section4" className="mt-5">
                                {setTitle("위약금 규정")}
                                <PenaltyInfo
                                    showReturnDelay={{ show: true, showTitle: true, showDivider: true }}
                                    showUncharged={{ show: true, showTitle: true, showDivider: true, className: "mt-4" }}
                                    showBreach={{ show: true, showTitle: true, showDivider: true, className: "mt-4" }}
                                    showEtc={{ show: true, showTitle: true, className: "mt-4" }}
                                />
                            </div>
                            <div id="section5" className="mt-5">
                                {setTitle("자주 하는 질문")}
                                <Faq className="mb-5 mobileFaq" faqIds={[8, 9, 6, 13, 7, 14]} />
                            </div>
                        </div>
                        <LayoutFooter type="rent" orderType={true} />
                        <Button className={`btn-dark mt-3 ${styles.btnBottom}`} disabled={isDisabled} type="primary" onClick={() => setIsOpen(true)}>
                            {isDisabled ? "오픈 준비중" : String.booking}
                        </Button>
                        <MobileDrawer
                            isOpen={isOpen}
                            content={
                                <div className="space-page" style={{ paddingTop: 0, paddingBottom: 140, marginTop: "-1rem" }}>
                                    <ReservationInfo
                                        value={values}
                                        disabledRanges={disabledRanges}
                                        rentPeriodLimits={rentPeriodLimits}
                                        rentPeakSeasons={rentPeakSeasons}
                                        onChange={onChange}
                                    />
                                    <Divider className="custom-divider" />
                                    <ReservationDetail
                                        showTitle={true}
                                        value={values}
                                        rentPeakSeasons={rentPeakSeasons}
                                        onResult={(price, totalPrice, userCouponDiscounted, multiCouponDiscounted) => {
                                            onChange("price", price);
                                            onChange("totalPrice", totalPrice);
                                            onChange("userCouponDiscounted", userCouponDiscounted);
                                            onChange("multiCouponDiscounted", multiCouponDiscounted);
                                        }}
                                    />
                                    <Button
                                        className={`btn-dark ${styles.btnBooking}`}
                                        type="primary"
                                        onClick={onClick}
                                        disabled={
                                            isDisabled
                                                ? true
                                                : !values?.startDate ||
                                                  !values.endDate ||
                                                  moment(values.startDate).isSame(values.endDate) ||
                                                  Utils.getCurrentTime().isBefore(Common.OPEN_DATE)
                                        }
                                    >
                                        {Utils.getCurrentTime().isBefore(Common.OPEN_DATE)
                                            ? "오픈 대기중(" + moment(Common.OPEN_DATE).format("MM/DD HH:mm") + "~)"
                                            : isLogout
                                            ? "로그인 후 예약하기"
                                            : String.booking}
                                    </Button>
                                </div>
                            }
                            onClose={() => setIsOpen(false)}
                        />
                    </div>
                )}
            </MobileView>
            <BrowserView className="browser" style={{ minHeight: "calc(100vh - 75px)", display: "grid" }}>
                {!loadingComplete ? (
                    <CustomLoading />
                ) : (
                    <Col
                        sm={{ span: 24 }}
                        lg={{ span: 18, offset: 3 }}
                        xl={{ span: 18, offset: 3 }}
                        xxl={{ span: 16, offset: 4 }}
                        className="fixed-page p-4"
                    >
                        <Row gutter={[30, 30]} style={{ paddingTop: 15 }}>
                            <Col span={14}>
                                <Title info={title} />
                                <Divider className="mb-4 mt-4" />
                                <p className="text-bold">보유시설</p>
                                <CarOption options={rentCar?.rentOptions} />
                                <Tabs className="model-tabs tab-sm mb-5 mt-4" defaultActiveKey="1" items={tabItems} />
                                <LayoutFooter type="rent" orderType={true} />
                            </Col>
                            <Col span={10}>
                                <div className={styles.cardWrapper}>
                                    <Card className={styles.card}>
                                        <ReservationInfo
                                            value={values}
                                            disabledRanges={disabledRanges}
                                            rentPeriodLimits={rentPeriodLimits}
                                            rentPeakSeasons={rentPeakSeasons}
                                            onChange={onChange}
                                        />
                                        <hr
                                            style={{
                                                marginTop: 20,
                                                marginBottom: 20,
                                                marginLeft: -24,
                                                marginRight: -24,
                                                borderColor: "#ccc",
                                                opacity: 1,
                                            }}
                                        />
                                        <ReservationDetail
                                            showTitle={true}
                                            value={values}
                                            rentPeakSeasons={rentPeakSeasons}
                                            onResult={(price, totalPrice, userCouponDiscounted, multiCouponDiscounted) => {
                                                onChange("price", price);
                                                onChange("totalPrice", totalPrice);
                                                onChange("userCouponDiscounted", userCouponDiscounted);
                                                onChange("multiCouponDiscounted", multiCouponDiscounted);
                                            }}
                                        />
                                        <Button
                                            className="btn-dark mt-3 w-100"
                                            type="primary"
                                            disabled={
                                                isDisabled
                                                    ? true
                                                    : !values?.startDate ||
                                                      !values.endDate ||
                                                      moment(values.startDate).isSame(values.endDate) ||
                                                      Utils.getCurrentTime().isBefore(Common.OPEN_DATE)
                                            }
                                            onClick={onClick}
                                        >
                                            {isDisabled
                                                ? "오픈 준비중"
                                                : Utils.getCurrentTime().isBefore(Common.OPEN_DATE)
                                                ? "오픈 대기중(" + moment(Common.OPEN_DATE).format("MM/DD HH:mm") + "~)"
                                                : isLogout
                                                ? "로그인 후 예약하기"
                                                : String.booking}
                                        </Button>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                )}
            </BrowserView>
        </>
    );
}

export default Details;

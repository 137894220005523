import React from "react";
import { Button, Row, Modal, Col } from "antd";
import { useNavigate } from "react-router-dom";

import * as String from "../commons/string";
import * as Common from "../commons/common";
import * as Utils from "../utils/utils";

import styles from "../pages/ruta40/Home.module.css";

declare const window: typeof globalThis & {
    webkit: any;
};

function NewYearPopup({ open, className, onChangedOpen }: { open: boolean; className?: string; onChangedOpen: (opened: boolean) => void }) {
    const navigate = useNavigate();
    const handleClose = () => {
        onChangedOpen(false);
    };

    const handleCloseToday = () => {
        const saveDay = new Date();
        const obj = {
            value: saveDay.toDateString(),
            expire: new Date(saveDay.setDate(saveDay.getDate() + 1)),
        };

        const newYearPopupClosePeriod = JSON.stringify(obj);
        window.localStorage.setItem("newYearPopupClosePeriod", newYearPopupClosePeriod);
        onChangedOpen(false);
    };

    return (
        <Modal
            closable={false}
            maskClosable={false}
            mask={false}
            className={`${styles.popup} homePopup ${className}`}
            open={open}
            onOk={handleClose}
            onCancel={handleClose}
            footer={
                <Row>
                    <Col style={{ textAlign: "start" }} span={12}>
                        <Button className={styles.popupCloseAlldayButton} type="text" onClick={handleCloseToday}>
                            오늘 하루 닫기
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button className={styles.popupCloseButton} type="text" onClick={handleClose}>
                            {String.close}
                        </Button>
                    </Col>
                </Row>
            }
        >
            <div
                className={styles.newYearPopupBody}
                onClick={() =>
                    Utils.checkDomain() === Common.Domain.RUTA40
                        ? navigate(Common.PAGE_NOTICE_DETAIL + "/12")
                        : navigate(Common.PAGE_NOTICE_DETAIL + "/32")
                }
            ></div>
        </Modal>
    );
}

export default NewYearPopup;

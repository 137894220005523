import React from "react";
import { Badge, Image, Space, Tag } from "antd";
import { NumericFormat } from "react-number-format";

import Won from "../../assets/images/home/rent/icon_won.svg";
import styles from "./GoodsCard.module.css";

import * as String from "../../commons/string";
import * as TypeDTO from "../../commons/typeDTO";
import * as Utils from "../../utils/utils";

function GoodsCard({
    alignType,
    rentCar,
    onClick,
}: {
    alignType?: boolean;
    rentCar: TypeDTO.RentCarDto;
    onClick: (carId: number, b2bId: number | undefined | null) => void;
}) {
    const tags = rentCar.rentRestrictions.map((restriction) => restriction.rentRestrictionName);

    return (
        <div className={`${styles.body} mb-4`} onClick={() => onClick(rentCar.rentCarId, rentCar.rentRegion.b2bId)}>
            <Badge.Ribbon
                text={
                    <Space size={4}>
                        <span className={styles.topText}>{String.coupon}</span>
                        <span className={styles.middleText}>{String.available}</span>
                    </Space>
                }
                className={styles.badge}
            >
                <Image className={styles.image} src={Utils.getCarImage(rentCar.title.toString())} preview={false} />
            </Badge.Ribbon>
            <p style={{ fontSize: "14px", color: "#1b2b41b0" }}>{rentCar.rentRegion.rentRegionName}</p>
            <p className={styles.title} style={{ fontSize: "16px" }}>
                {rentCar.title}
            </p>
            {alignType ? (
                <Space size={3} style={{ display: "flex", alignItems: "center", alignContent: "center", flexWrap: "wrap" }}>
                    <img src={Won} />
                    <NumericFormat
                        className={styles.priceDiscounted}
                        value={rentCar.discountPrice || rentCar.price}
                        thousandSeparator={true}
                        suffix="~"
                        displayType="text"
                    />
                    {rentCar.discountPrice && (
                        <>
                            <NumericFormat className={styles.price} value={rentCar.price} thousandSeparator={true} displayType="text" />
                            <NumericFormat
                                className={styles.discountRate}
                                value={Utils.getDiscountRate(rentCar.price, rentCar.discountPrice)}
                                thousandSeparator={true}
                                displayType="text"
                                suffix="%"
                            />
                        </>
                    )}
                </Space>
            ) : (
                <>
                    <p className={styles.description}>{rentCar.description}</p>
                    {rentCar.discountPrice && (
                        <div style={{ display: "flex", alignItems: "center", alignContent: "center", flexWrap: "wrap" }}>
                            <NumericFormat
                                className={styles.discountRate}
                                value={Utils.getDiscountRate(rentCar.price, rentCar.discountPrice)}
                                style={{ marginRight: "8px" }}
                                thousandSeparator={true}
                                displayType="text"
                                suffix="%"
                            />
                            <NumericFormat className={styles.price} value={rentCar.price} thousandSeparator={true} displayType="text" />
                        </div>
                    )}
                    <div style={{ display: "flex", alignContent: "center", flexFlow: "wrap", alignItems: "center" }}>
                        <img src={Won} />
                        <NumericFormat
                            className={styles.priceDiscounted}
                            value={rentCar.discountPrice || rentCar.price}
                            thousandSeparator={true}
                            displayType="text"
                            suffix="~"
                        />
                    </div>
                </>
            )}

            <div className={styles.tagBody}>
                {tags.map((tag: string) => (
                    <Tag key={tag} className={styles.tag}>
                        {tag}
                    </Tag>
                ))}
            </div>
        </div>
    );
}
export default GoodsCard;
